 <footer>
    <div>
      <img
      width="120"
      height="40"
      alt="Radiali"
      src="/assets/images/radiali-logo.png"/>
    </div>
    <div>
      {{"FOOTER.LINK" | translate}} 
    </div>
    <div>
      <span class="link" (click)="navigateTo('contactus')">Contact</span>
    </div>
</footer>
