<div class="content">
        <h1>{{"SERVICES.TITLE" | translate}}</h1>
        <div class="card">
            <div class="row">
                    <div class="cell">
                        <h2>
                            {{"SERVICES.SUB-TITLE1" | translate}}
                        </h2>
                        <p>{{"SERVICES.MSG1" | translate}} </p>
                        <p>{{"SERVICES.MSG1.1" | translate}} </p>
                        <p>{{"SERVICES.MSG1.2" | translate}} </p>
                        <p>{{"SERVICES.MSG1.3" | translate}} </p>
                        <p>{{"SERVICES.MSG1.4" | translate}} </p>
                        <p>{{"SERVICES.MSG1.5" | translate}} </p>
                        <p>{{"SERVICES.MSG1.6" | translate}} </p>
                    </div>
                    <div class="with-image">
                        <img
                        width="320"
                        height="240"
                        alt="Radiali"
                        src="/assets/images/services_1.jpg"
                    />
                    </div>
            </div>
            <div class="row-rewerse-wrap">
                    <div  class="with-image">
                        <img
                        width="320"
                        height="240"
                        alt="Radiali"
                        src="/assets/images/services_2.jpg"/>
                    </div>
                    <div  class="cell">
                        <h2>
                            {{"SERVICES.SUB-TITLE2" | translate}}
                        </h2>
                        <p>{{"SERVICES.MSG2" | translate}}</p>
                        <p>{{"SERVICES.MSG2.1" | translate}} </p>
                        <p>{{"SERVICES.MSG2.2" | translate}} </p>

                    </div>

            </div>
            <div class="row">
                <div class="cell">
                    <h2>
                        {{"SERVICES.SUB-TITLE3" | translate}}
                    </h2>
                    <p>{{"SERVICES.MSG3" | translate}} </p>
                    <p>{{"SERVICES.MSG3_1" | translate}} </p>
                    <p>{{"SERVICES.MSG3_2" | translate}} </p>

                </div>
                <div class="with-image">
                    <img width="320" height="240"
                    alt="Radiali"
                    src="/assets/images/services_3.jpg"
                />
                </div>
        </div>
        <div class="row-rewerse-wrap">
                <div  class="with-image">
                    <img width="320" height="240"
                    alt="Radiali"
                    src="/assets/images/services_4.jpg"/>
                </div>
                <div  class="cell">
                    <h2>
                        {{"SERVICES.SUB-TITLE4" | translate}}
                    </h2>
                    <p>{{"SERVICES.MSG4" | translate}} </p>
                    <p>{{"SERVICES.MSG4_1" | translate}} </p>
                </div>

        </div>
        </div>

</div>