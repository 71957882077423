<!-- Toolbar -->
<div class="toolbar" role="banner">
  <div>
    <img
    width="120"
    height="40"
    alt="Radiali"
    src="/assets/images/radiali-logo.png"/>

  </div>
  <div class="icons">
      <div *ngIf="!isMobile" class="icon-and-text">
        <span class="material-icons"> perm_phone_msg </span>
        <span class="text">40.767.594.267</span>
      </div>
      <div class="spacer" *ngIf="!isMobile"></div>
      <div id="menuIcon" *ngIf="isMobile" (click)="showMenu()">
        <mat-icon>more_vert</mat-icon>
      </div>
      <div routerLink="login" *ngIf="!user.isLoggedIn" style="margin-left:10px">
        <span class="material-icons" style="cursor: pointer;">account_circle</span>
      </div>
      <div class="logIn-Icons" routerLink="login" *ngIf="user.isLoggedIn" style="margin-left:20px">
        <div class="icon-and-text">
          <span class="text">{{ user.firstName + ' ' + user.lastName + '   ' }}</span>
          <span class="material-icons">account_circle</span> 
        </div>
        <div *ngIf="user.isLoggedIn" style="margin-left:20px">  
          <span class="material-icons" (click)="doLogout()" style="cursor: pointer;" >logout</span>   
        </div>     
      </div>
      <div>
        <span class="text" (click)="changeLang('en')" *ngIf="translate.currentLang==='ro'">EN</span>
        <span class="text" (click)="changeLang('ro')" *ngIf="translate.currentLang==='en'">RO</span>
      </div> 
    </div>    

  </div>
