<form class="product-form"  [formGroup]="productForm">
  <div class="spinner-container" *ngIf="isSaving">
    <mat-progress-spinner class="spinner"
    [color]="'primary'"
    [mode]="'indeterminate'"
    [value]="50">
    </mat-progress-spinner>
  </div>
    <div class="row">
        <mat-form-field class="product-half-width">
            <mat-label>Id-ul produsului</mat-label>
            <input matInput formControlName="produs_id">
          </mat-form-field>
          <mat-form-field class="product-half-width">
              <mat-label>Numele produsului</mat-label>
              <input matInput placeholder="Nume Produs" formControlName="nume">
            </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="product-half-width">
            <mat-label>Model</mat-label>
            <input matInput placeholder="Model Produs" formControlName="model">
          </mat-form-field>
        
        <mat-form-field class="product-half-width">
            <mat-label>Dimensiuni</mat-label>
            <textarea matInput 
            formControlName="dimensiuni"></textarea>
          </mat-form-field>
    </div>
    <div class="row">       
        <mat-form-field class="product-half-width">
            <mat-label>Descriere</mat-label>
            <textarea matInput 
            formControlName="description"
            placeholder="Ex. produs specific ..."></textarea>
          </mat-form-field>
    </div>

    <mat-divider></mat-divider>
    <div class="row-buttons">
      <button mat-raised-button color="accent" (click)="doCancel()">Inapoi</button>
      <button mat-raised-button color="primary" (click)="doSave()">Salveaza</button>
      <button mat-raised-button color="primary" (click)="doCallForDelete()">Sterge produs</button>
      <button *ngIf="!isAddingNew" mat-raised-button color="primary" (click)="addNew()">Adauga produs</button>
    </div>
    <h3>{{message}}</h3>
  </form>
