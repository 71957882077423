<div class="content" *ngIf="showContent">
    <div class="card-container">
        <div class="card card-small"
        (click)="handleNavigation()"
        routerLinkActive="activeLink"
        routerLink="home" tabindex="0">
          <span>{{"MAIN-MENU.HOME" | translate}}</span>
        </div>    
        <div class="card card-small" 
        (click)="handleNavigation()"
        routerLinkActive="activeLink" routerLink="about" tabindex="0"> 
          <span>{{"MAIN-MENU.ABOUT" | translate}}</span>
        </div>
        <div class="card card-small"
        (click)="handleNavigation()"
        routerLinkActive="activeLink" routerLink="services" tabindex="0">
           <span>{{"MAIN-MENU.SERVICES" | translate}}</span>
        </div>
        <div class="card card-small"  (click)="handleNavigation()"
           routerLinkActive="activeLink" routerLink="products" tabindex="0">
          <span>{{"MAIN-MENU.PRODUCTS" | translate}}</span>
       </div>
        <div class="card card-small"
        (click)="handleNavigation()"
        routerLinkActive="activeLink" routerLink="contactus" tabindex="0">
          <span>{{"MAIN-MENU.CONTACT" | translate}}</span>
        </div>
      </div>
</div>
