<div class="content">
    <h1>{{"PRODUCTS.TITLE" | translate}}</h1>
    <div>
     <p>{{"PRODUCTS.MSG1" | translate}}</p> 
     <p>{{"PRODUCTS.MSG2" | translate}}</p>  
     <p>{{"PRODUCTS.MSG3" | translate}}</p> 
     <p>{{"PRODUCTS.MSG4" | translate}}</p>  

    </div>
    <div class="search">
    <h3>{{"PRODUCTS.PRODUCTS-LIST" | translate}} </h3>
        <mat-form-field>
            <mat-label>{{"PRODUCTS.SEARCH-IN-LIST" | translate}} </mat-label>
            <input id="type-ahead" matInput type="text" [(ngModel)]="forProdSearch">
            <mat-icon matSuffix mat-icon-button  *ngIf="forProdSearch" (click)="forProdSearch='';searchProduct()">close</mat-icon>
          </mat-form-field>
    </div>
    <hr>
    <div class="card" *ngIf="isLoading">
      <mat-progress-spinner
      [color]="'primary'"
      [mode]="'indeterminate'"
      [value]="50">
      </mat-progress-spinner>
    </div>

    <div class="card">
        <span *ngIf="noSearchResult">Nu sunt produse pentru cautarea aceasta</span>
        <table *ngIf="!noSearchResult" mat-table [dataSource]="dataSource" matSort>
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->    
            <!-- Code Column -->
            <ng-container matColumnDef="produs_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Produs Id </th>
              <td mat-cell *matCellDef="let element"
              [ngClass]="{'navigate-cell': user.isLoggedIn}"
              (click)="navigateToEditProduct(element.produs_id)"
              > {{element.produs_id}} </td>
            </ng-container>
          
            <!-- Model Column -->
            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Model</th>
              <td mat-cell *matCellDef="let element"
              [ngClass]="{'navigate-cell': user.isLoggedIn}"
              (click)="navigateToEditProduct(element.produs_id)"
              > {{element.model}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="nume">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nume </th>
              <td mat-cell *matCellDef="let element"
              [ngClass]="{'navigate-cell': user.isLoggedIn}"
              (click)="navigateToEditProduct(element.produs_id)"> {{element.nume}} </td>
            </ng-container>
            <ng-container matColumnDef="dimensiuni">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Dimensiuni </th>
              <td mat-cell *matCellDef="let element"> {{element.dimensiuni}} </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descriere </th>
              <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="paginator">
            <mat-paginator [pageSizeOptions]="[5, 10, 20, 100]" showFirstLastButtons></mat-paginator>
          </div>
          
    </div>
</div>
