
<div class="content">
    <div>
        <h1>{{"ABOUT.TITLE" | translate}}</h1>
        <p>{{"ABOUT.MSG" | translate}}</p>
        <p>{{"ABOUT.MSG_1" | translate}}</p>
        <p>{{"ABOUT.MSG_2" | translate}}</p>
        <p>{{"ABOUT.MSG_3" | translate}}</p>
        <p>{{"ABOUT.MSG_4" | translate}}</p>

    </div>
    <!-- <div>
        <img
        width="320"
        height="240"
        alt="Radiali"
        src="/assets/images/maxresdefault2.jpg"
      />
    </div> -->
</div>
