<div class="main">
<h3><span>{{"CONTACT-US.CONTACT-INFO" | translate}}</span></h3>
<div>
    <span class="material-icons"> perm_phone_msg </span>
    <span class="phone-nbr"> {{"CONTACT-US.PHONE" | translate}} 40.767.594.267</span>
</div>
    

<div>
    <span class="material-icons">markunread  </span>
     Email: office@seniledecauciuc.ro
</div>


<!-- <div>
    <span class="material-icons">
        perm_phone_msg
        </span>
    Telefon:
    0767-594-267
</div> -->

<div class="row">
    <span class="material-icons">  send </span>
    Fax: 0359-814719
</div>

</div>
