<div class="content">
        <h1>{{"HOME.SUB-TITLE" | translate}}</h1>
        <div class="card">
                <div class="cell">
                    <p>{{"HOME.MAIN-MSG" | translate}}</p>
                    <p>{{"HOME.MAIN-MSG_1" | translate}}</p>
                    <p>{{"HOME.MAIN-MSG_2" | translate}}</p>
                </div>
                <div class="with-image">
                    <img
                    width="320"
                    height="240"
                    alt="Radiali"
                    src="/assets/images/maxresdefault2.jpg"/>
                </div>
        </div>
</div>
